<template>
  <v-card class="ma-5">
    <DateInputs
      ref="dataInputRelatorio"
      v-model="relatorios"
      @searching="loading = $event"
      @filter="filter"
    />

    <base-loading v-if="loading" />
    <v-card v-else class="ma-2">
      <div>
        <gmap-map
          :center="center"
          :zoom="14"
          style="width: 100%; height: 500px"
        >
          <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :clickable="true"
            @click="center = m.position"
            @mouseover="showByIndex = index"
            @mouseout="showByIndex = null"
          >
            <gmap-info-window :opened="showByIndex === index">
              <p style="text-align: center; font-weight: bold">
                {{ infomarkers[index].NomeFantasia }}
              </p>
              <p>Produtor: {{ infomarkers[index].NomeProdutor }}</p>
              <p>Consultor: {{ infomarkers[index].NomeConsultor }}</p>
              <p>Agroindústria: {{ infomarkers[index].NomeAgroindustria }}</p>
            </gmap-info-window>
          </gmap-marker>
        </gmap-map>
      </div>
      <div v-show="visible">
        <crud-list
          v-model="reports"
          :headers="headers"
          :actions="actions"
          :slots="['item.Status']"
          :search-init="search"
          :search.sync="search"
        >
          <template v-slot:print>
            <v-btn
              v-if="statusBt"
              style="color: white; font-size: 12px; font-weight: 500"
              small
              elevation="2"
              class="ml-3"
              color="blue darken-4"
              @click="printAll"
            >
              Mostrar todas fazendas
            </v-btn>
            <v-btn
              v-else
              style="color: white; font-size: 12px; font-weight: 500"
              small
              elevation="2"
              class="ml-3"
              color="blue darken-4"
              @click="removeAll"
            >
              Retirar todas fazendas
            </v-btn>
          </template>
          <template v-slot:[`item.Status`]="{ item }">
            <v-btn
              v-if="item.Status === 'Ativa'"
              rounded
              small
              plain
              text
              class="teal lighten-1 white--text"
              >{{ item.Status }}</v-btn
            >
            <v-btn
              v-else
              text
              rounded
              small
              plain
              class="red lighten-1 white--text"
              >{{ item.Status }}</v-btn
            >
          </template>
        </crud-list>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import DateInputs from './DateInputs'

export default {
  components: {
    DateInputs,
  },

  data() {
    const viewReport = {
      title: 'Ver localização',
      color: 'primary',
      click: item => this.visualizar(item),
      icon: 'mdi-eye',
    }
    const selectReport = {
      title: 'checkbox',
      color: 'blue darken-3',
      click: item => this.selectItem(item),
    }
    let btnAction = []
    btnAction.push(viewReport, selectReport)

    return {
      visible: false,
      showByIndex: 0,
      coordenadas: [],
      itemMap: [],
      center: { lat: 0, lng: 0 },
      markers: [],
      infomarkers: [],
      reports: [],
      statusBt: false,
      loading: true,
      data: true,
      datafilter: true,
      relatorios: [],
      relatoriosData: [],
      actions: btnAction,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'center',
        },
        {
          text: 'Status',
          sortable: true,
          width: 'auto',
          value: 'Status',
          align: 'left',
          filterable: true,
        },
        {
          align: 'center',
          sortable: true,
          text: 'Fazenda',
          value: 'NomeFantasia',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Produtor',
          value: 'NomeProdutor',
          width: 'auto',
        },
        {
          text: 'Consultor',
          align: 'center',
          sortable: true,
          value: 'NomeConsultor',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Agroindústria',
          value: 'NomeAgroindustria',
          width: 'auto',
        },
      ],
    }
  },
  computed: {
    ...mapState(['relatorio_search']),
    search: {
      get() {
        return null
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_SEARCH', val)
      },
    },
  },

  mounted() {
    this.$root.$on('updateFilter', data => {
      this.data = data
      this.updateFilter(this.data)
    })
    this.geolocate()
  },

  methods: {
    async printAll() {
      this.filter(this.data)
      this.statusBt = false
    },

    async removeAll() {
      this.reports.forEach(item => {
        this.selectItem(item)
        item.checked = false
      })
      this.statusBt = true
    },

    visualizar(item) {
      if (item.Latitude && item.Longitude) {
        this.center = {
          lat: parseFloat(item.Latitude),
          lng: parseFloat(item.Longitude),
        }

        const index = this.infomarkers.indexOf(item)

        this.showByIndex = index
      } else {
        this.Swal.fire(
          'Atenção',
          'A fazenda selecionada não possui os dados de localização (Latitude e Longitude) em seu cadastro.',
          'warning',
        )
      }
    },

    selectItem(item) {
      const find = this.infomarkers.find(
        fazenda => fazenda.CodFazenda === item.CodFazenda,
      )

      if (!find) {
        this.coordenadas = {
          position: {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
          },
        }
        this.markers.push(this.coordenadas)
        this.infomarkers.push(item)
      } else {
        const index = this.infomarkers.indexOf(item)
        this.markers.splice(index, 1)
        this.infomarkers.splice(index, 1)
      }
      this.markers.sort()
      this.infomarkers.sort()
      this.geolocate()
    },

    filter(data) {
      this.data = data
      this.markers = []
      this.infomarkers = []
      this.reports = []

      data.map(item => {
        this.itemMap = {
          CodFazenda: item.CodFazenda,
          Latitude: item.Latitude,
          Longitude: item.Longitude,
          NomeAgroindustria: item.NomeAgroindustria,
          NomeConsultor: item.NomeConsultor,
          NomeFantasia: item.NomeFantasia,
          NomeProdutor: item.NomeProdutor,
          Status: item.Status,
        }
        if (item.Latitude != '' && item.Longitude != '') {
          this.center = {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
          }
          this.coordenadas = {
            position: {
              lat: parseFloat(item.Latitude),
              lng: parseFloat(item.Longitude),
            },
          }

          this.infomarkers.push(this.itemMap)
          this.reports.push(this.itemMap)
          this.markers.push(this.coordenadas)
        } else {
          this.reports.push(this.itemMap)
        }
      })

      this.reports.forEach(item => {
        if (item.Latitude != '' && item.Longitude != '') {
          item.checked = true
        } else {
          item.checked = false
        }
      })

      this.geolocate()
      this.visible = true
    },

    updateFilter(data) {
      this.coordenadas = []
      this.markers = []
      this.infomarkers = []
      this.center = { lat: 0, lng: 0 }
      this.datafilter = data
      this.datafilter.map(item => {
        this.itemMap = {
          CodFazenda: item.CodFazenda,
          Latitude: item.Latitude,
          Longitude: item.Longitude,
          NomeAgroindustria: item.NomeAgroindustria,
          NomeConsultor: item.NomeConsultor,
          NomeFantasia: item.NomeFantasia,
          NomeProdutor: item.NomeProdutor,
          Status: item.Status,
        }
        if (
          item.Latitude != '' &&
          item.Longitude != '' &&
          item.checked == true
        ) {
          this.center = {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
          }
          this.coordenadas = {
            position: {
              lat: parseFloat(item.Latitude),
              lng: parseFloat(item.Longitude),
            },
          }
          this.infomarkers.push(this.itemMap)
          this.markers.push(this.coordenadas)
        }
      })
      this.geolocate()
    },

    geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    },
  },
}
</script>

<style>
.v-icon.v-icon::after {
  background-color: white;
}
</style>
