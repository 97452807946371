<template>
  <v-row class="px-5" style="padding-top: 20px">
    <v-col class="pa-5" cols="12" sm="7" md="4">
      <v-autocomplete
        v-model="form.consultor"
        prepend-icon="mdi-account-search"
        dense
        clearable
        autocomplete="autocomplete_off_hack_xfr4!k"
        item-text="Nome"
        item-value="id"
        label="Selecione um Consultor"
        validate-on-blur
        :loading="loading.consultor"
        :disabled="loading.consultor"
        :items="consultores"
        @change="isSelect()"
      />
    </v-col>
    <v-col class="pa-5" cols="12" sm="7" md="4">
      <v-autocomplete
        v-model="form.agroindustria"
        prepend-icon="mdi-home-search"
        dense
        clearable
        autocomplete="autocomplete_off_hack_xfr4!k"
        item-text="name"
        item-value="id"
        label="Selecione uma Agroindústria"
        validate-on-blur
        :loading="loading.agroindustria"
        :disabled="loading.agroindustria"
        :items="agroindustrias"
        @change="isSelect()"
      />
    </v-col>
    <v-col class="pa-3" cols="12" md="2">
      <v-row justify="end" align="center">
        <v-btn
          style="color: white; border-radius: 8px; margin-left: 40%"
          color="blue darken-4"
          @click="getRelatorios"
        >
          <span style="font-size: 16px; font-weight: 600">{{ btnNome }}</span>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { _orderByName } from '../../../utils/formatter'
export default {
  name: 'DateInputsBP',

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: {
        consultor: true,
        agroindustria: true,
      },
      form: {
        agroindustria: '',
        consultor: '',
      },

      consultores: [],
      agroindustrias: [],

      searching: true,
      select: false,
    }
  },

  computed: {
    btnNome() {
      return this.select ? 'Buscar' : 'Buscar Todas'
    },
  },

  created() {
    this.getConsultores()
    this.getAgroindustrias()
  },

  methods: {
    getAgroindustrias() {
      this.api.get.agroindustrias().then(data => {
        this.agroindustrias = data
        this.agroindustrias = this.agroindustrias.filter(item => {
          return item.pessoa?.pessoajuridica?.NomeFantasia
        })
        this.agroindustrias = this.agroindustrias.map(item => {
          return {
            name: item.pessoa.pessoajuridica.NomeFantasia,
            id: item.CodAgroindustria,
          }
        })
        this.loading.agroindustria = false
        this.$emit('searching', false)
      })
    },

    getConsultores() {
      if (
        this.$user.get().role === 'admin' ||
        this.$user.get().role === 'gestor'
      ) {
        this.api.get.consultores().then(data => {
          this.consultores = _orderByName({
            data,
            key: 'Nome',
          })
          this.loading.consultor = false
        })
      } else {
        this.loading.consultor = false
      }
    },

    getRelatorios() {
      this.searching = true
      this.$emit('searching', true)
      this.api.get
        .fazendasMap(this.form.agroindustria, this.form.consultor)
        .then(data => {
          this.$emit('markers', false)
          this.$emit('input', data)
          this.$emit('filter', data)
          this.searching = false
          this.$emit('searching', false)
        })
      this.$root.$emit('searchingFilter', true)
    },

    isSelect() {
      if (
        (!this.form.agroindustria ||
          typeof this.form.agroindustria === 'undefined') &&
        (!this.form.consultor || typeof this.form.consultor === 'undefined')
      ) {
        this.select = false
      } else {
        this.select = true
      }
    },
  },
}
</script>
